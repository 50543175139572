import React from "react"
import { graphql,Link } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import SEO from "../components/SEO"
import Container from "react-bootstrap/Container"
import Img from "gatsby-image"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

export default ({data}) => (
  <div>
    <SEO />
    <Header headerText="Home Page" />
    <Container fluid className="hp-container">    
      <div className="experiences">
        {data.allMarkdownRemark.edges.map(({ node }, index) => (
          <Row key={node.id} noGutters className="experience">
            <Col md={(index % 2 === 1) ? {span:6, order: 2} : {span:6, order: 1}} sm={{span:12, order: 1}} >                  
              {node.frontmatter.photo && node.frontmatter.photo.childImageSharp && (
                <div>
                  <Img
                    className="image-block"
                    fluid={node.frontmatter.photo.childImageSharp.fluid} 
                    alt="Room Image" 
                    objectFit="cover"
                    objectPosition="50% 50%" 
                  />            
                </div>
              )}           
            </Col>
            <Col md={(index % 2 === 1) ? {span:6, order: 1} : {span:6, order: 2}} sm={{span:12, order: 2}} >
                <div className="description">
                  <h2>{node.frontmatter.title}</h2>                  
                  <div                    
                    dangerouslySetInnerHTML={{ __html: node.excerpt }}
                  />
                  <Link to={node.frontmatter.path} className="btn btn-primary mt-4">Read More</Link>
                </div>
            </Col>
          </Row>
        ))}
      </div>
    </Container>    
    <Footer />
  </div>
)


export const query = graphql`
  query allServicesQuery {
    allMarkdownRemark(filter: {fields: {contentType: {eq: "services"}}}) {
      totalCount
      edges {
        node {
          id
          html
          excerpt(pruneLength: 250)
          fields {
            slug
            contentType
          }
          frontmatter {
            description
            title
            path                  
            photo {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`